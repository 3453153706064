import { useHelper } from "@react-three/drei";
import { useControls, folder } from "leva";
import { useRef } from "react";
import { SpotLight, SpotLightHelper } from "three";

type ModelProps = {};

export const MoonLight = ({}: ModelProps) => {
  const spotLightRef = useRef<SpotLight>(null!);
  const helper = useHelper(spotLightRef, SpotLightHelper, "blue");

  const [
    {
      moon_helper,
      moon_color,
      moon_radius,
      moon_distance,
      moon_intensity,
      moon_position,
    },
    set,
  ] = useControls(() => ({
    Moon: folder(
      {
        moon_helper: { value: false },
        moon_position: {
          value: [4.5, 2, -20],
          step: 0.01,
        },
        moon_intensity: {
          value: 20,
          min: 0,
          max: 50,
          step: 0.01,
        },
        moon_color: "#fffb5b",
        moon_radius: {
          value: Math.PI / 5,
          min: 0,
          max: Math.PI / 2,
          step: 0.01,
        },
        moon_distance: {
          value: 70,
          min: 0,
          max: 150,
          step: 1,
        },
      },
      { collapsed: true }
    ),
  }));

  if (helper.current) helper.current.visible = moon_helper;
  return (
    <spotLight
      color={moon_color}
      shadow-normalBias={1}
      position={moon_position}
      intensity={moon_intensity}
      castShadow
      receiveShadow
      ref={spotLightRef}
      distance={moon_distance}
    />
  );
};
