import { Suspense } from "react";

import { ReplayModel } from "./ReplayModel";

type Props = {
  stadium: string;
  replayData: any;
  players: any[];
};

export const Replay = ({ stadium: replayPath, replayData, players }: Props) => {
  return (
    <group castShadow>
      <Suspense fallback={null}>
        <ReplayModel
          stadiumPath={replayPath}
          replayData={replayData}
          players={players}
        />
      </Suspense>
    </group>
  );
};
