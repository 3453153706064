import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";
import { Object3D } from "three";

export const getGLBInfo = (nodes: Object3D) => {
  const stadiumInfo = { objects: 0, vertices: 0, triangles: 0 };
  nodes.traverseVisible((obj) => {
    const o = obj as unknown as Instance;
    stadiumInfo.objects++;

    if (o.isMesh) {
      const geometry = o.geometry;

      stadiumInfo.vertices += geometry.attributes.position.count;

      if (geometry.index !== null) {
        stadiumInfo.triangles += geometry.index.count / 3;
      } else {
        stadiumInfo.triangles += geometry.attributes.position.count / 3;
      }
    }
  });

  return stadiumInfo;
};
