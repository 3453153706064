import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Group, Vector2 } from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";

import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { applyProps, useFrame, useGraph } from "@react-three/fiber";
import { Sticky } from "../Sticky/Sticky";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";

// const modelPath = "/CryptoNaut03.glb";

type ModelProps = {
  animation: string;
  modelPath: string;
};

export function useSkinnedMeshClone(path: string) {
  const gltf = useGLTF(path);
  const { scene, materials, animations } = gltf;
  const clonedScene = useMemo(() => clone(scene), [scene]);
  // const graph = useGraph(scene);
  // const { nodes } = graph;
  const { nodes } = useGraph(clonedScene);

  // return { scene, materials, animations, nodes };
  return { scene: clonedScene, materials, animations, nodes };
}
export const AllStarModel = ({ modelPath, animation }: ModelProps) => {
  const { nodes, animations } = useSkinnedMeshClone(modelPath);
  const allStarRef = React.useRef<Group>(null);

  const { actions, mixer } = useAnimations(animations, allStarRef);
  //   // TODO: check https://codesandbox.io/s/pecl6 for fadeIn/Out of animations

  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    actions[animation]?.reset().fadeIn(0.5).play();
    const action = actions[animation];

    const clip = action?.getClip();
    if (clip) {
      console.log(clip.tracks);
      // clip.tracks
    }
    // In the clean-up phase, fade it out
    return () => {
      actions[animation]?.fadeOut(0.5);
    };
  }, [actions, animation]);

  useLayoutEffect(() => {
    // enable the shadows, they might not be present in the blender export?
    nodes.Scene.traverse((obj) => {
      // HACKERDYHACK
      const o = obj as unknown as Instance;
      if (o.isMesh) {
        applyProps(o, {
          castShadow: true,
          receiveShadow: true,
          "material-envMapIntensity": 0.2,
        });
      }
    });
  }, [nodes.Scene]);

  return (
    <primitive
      ref={allStarRef}
      object={nodes.Scene}
      scale={0.85}
      castShadow={true}
      receiveShadow={true}
    />
  );
};

// useGLTF.preload(modelPath);
