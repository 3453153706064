import gsap from "gsap/dist/gsap";
import { useRef, useEffect } from "react";
import { ScoreType } from "./ReplayModel";

type ModelProps = {
  score: ScoreType;
  onAnimationComplete: () => void;
};

export const ShowGoalPopUp = ({ score, onAnimationComplete }: ModelProps) => {
  const effectRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    if (!effectRef.current) return;

    gsap.to(effectRef.current, {
      scale: 3,
      duration: 1,
      onComplete: () => {
        onAnimationComplete();
      },
    });
  }, [effectRef, onAnimationComplete]);

  return (
    <div>
      <p ref={effectRef} className="goal">
        Goal
      </p>
    </div>
  );
};

// useGLTF.preload(modelPath);
