import { useNavigate } from "react-router-dom";
import { Sticky } from "../components/Sticky/Sticky";
import { useAppContext } from "../context/AppContext";
import classNames from "classnames";
import "./Home.scss";
import { State } from "../components/LineUp/LineUp";
import { AVAILABLE_BRAINS, PLAYER_KEYS } from "../App";
import { useEffect, useState } from "react";

type Props = {};

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}
function Home() {
  const {
    currentScreen,
    setCurrentScreen,
    resetCamera,
    setResetCamera,
    charactersByKey,
    setCharactersByKey,
  } = useAppContext();

  const [brainIndex, setBrainIndex] = useState<number>(0);

  useEffect(() => {
    if (currentScreen.screen !== "brainSelection") return;
    const updated = { ...charactersByKey };
    updated[currentScreen.playerId] = {
      ...updated[currentScreen.playerId],
      brain: AVAILABLE_BRAINS[brainIndex],
    };

    setCharactersByKey(updated);
  }, [brainIndex]);

  const onBrainSelected = () => {
    setResetCamera(true);
    setCurrentScreen({ screen: "players" });
  };

  const onBrainBackClick = () => {
    setResetCamera(true);
    setCurrentScreen({ screen: "players" });
  };

  const onNextClick = () => {
    if (currentScreen.screen !== "brainSelection") return;
    const index = mod(brainIndex + 1, AVAILABLE_BRAINS.length - 1);
    setBrainIndex(index);
  };
  const onPrevClick = () => {
    if (currentScreen.screen !== "brainSelection") return;
    const index = mod(brainIndex - 1, AVAILABLE_BRAINS.length - 1);
    setBrainIndex(index);
  };

  return (
    <div className="landing-page">
      <div className="landing-page__logo">logo</div>
      <div className="landing-page__brain-ui">
        <div
          className={classNames({
            "landing-page__brain-ui__back": true,
            "landing-page__brain-ui__back--active":
              currentScreen.screen === "brainSelection",
          })}
        >
          <Sticky>
            <button
              className="button button--white"
              type="button"
              onClick={onBrainBackClick}
            >
              back
            </button>
          </Sticky>
        </div>
        <div
          className={classNames({
            "landing-page__brain-ui__confirm": true,
            "landing-page__brain-ui__confirm--active":
              currentScreen.screen === "brainSelection",
          })}
        >
          <Sticky>
            <button
              className="button button--white"
              type="button"
              onClick={onBrainSelected}
            >
              Select this brain
            </button>
          </Sticky>
        </div>
        <div
          className={classNames({
            "landing-page__brain-ui__next": true,
            "landing-page__brain-ui__next--active":
              currentScreen.screen === "brainSelection",
          })}
        >
          <Sticky>
            <button
              className="button button--white"
              type="button"
              onClick={onNextClick}
            >
              next
            </button>
          </Sticky>
        </div>
        <div
          className={classNames({
            "landing-page__brain-ui__prev": true,
            "landing-page__brain-ui__prev--active":
              currentScreen.screen === "brainSelection",
          })}
        >
          <Sticky>
            <button
              className="button button--white"
              type="button"
              onClick={onPrevClick}
            >
              prev
            </button>
          </Sticky>
        </div>
      </div>
    </div>
  );
}

export default Home;
