import React, { ReactNode, useCallback, useEffect, useRef } from "react";

import { useFrame } from "@react-three/fiber";
import { Vector2 } from "three";
//@ts-ignore
import { gsap } from "gsap/dist/gsap";

type ModelProps = {
  children: ReactNode;
  className?: string;
  stickyReach?: number;
};

export const Sticky = ({
  children,
  className,
  stickyReach = 100,
}: ModelProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const stickyContainerRef = React.useRef<HTMLDivElement>(null);
  const requestRef = React.useRef<number>();
  const mouse = useRef<Vector2>(new Vector2(0, 0));

  const animate = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      const pos = new Vector2(
        rect.x + rect.width / 2,
        rect.y + rect.height / 2
      );

      const distance = pos.distanceTo(mouse.current);
      const maxInfluenceDistance = stickyReach;
      if (distance < maxInfluenceDistance) {
        const pct = (distance / maxInfluenceDistance) * 0.25;
        const dx = pos.x - mouse.current.x;
        const dy = pos.y - mouse.current.y;
        const angle = Math.atan2(dy, dx);
        const x = Math.cos(angle) * -stickyReach * pct;
        const y = Math.sin(angle) * -stickyReach * pct;
        gsap.to(stickyContainerRef.current, { x, y });
      } else {
        gsap.to(stickyContainerRef.current, { x: 0, y: 0 });
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  };
  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();

    mouse.current.setX(event.clientX);
    mouse.current.setY(event.clientY);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      requestRef.current && cancelAnimationFrame(requestRef.current);
    };
  }, []); // Make sure the effect runs only once

  return (
    <div className={className} ref={containerRef}>
      <div ref={stickyContainerRef}>{children}</div>
    </div>
  );
};
