import { Object3D } from "three";
import { Vector3XYZ } from "./RecordedEvent";

export const generateSuperShotFX = (position: Vector3XYZ) => {
    console.log(`generating superShot fx from position: ${position.x, position.y, position.z}`)
    // TODO: add super shot fx
}

export const generateSuperSizeFx = (position: Vector3XYZ) => {
    console.log(`superSize FX from: ${position.x, position.y, position.z}`)
    // TODO: add explosion fx
}

export const generateTeleportFX = (fromPosition: Vector3XYZ, toPosition: Vector3XYZ) => {
    console.log(`telport FX at position: 
    ${fromPosition.x, fromPosition.y, fromPosition.z}
    to:
    ${toPosition.x, toPosition.y, toPosition.z}`)
    // TODO: May need teleport positions from/to for two effects
}

export const generateGoalFx = (team: 'teamA' | 'teamB') => {
    console.log(`team: ${team} made a goal!..`)
    // TODO: Add fx on either end of field dependent on the team. Hardcoded coordinates
}

export const generateDomeImpactFx = (position: Vector3XYZ,  normal: Vector3XYZ) => {
    console.log(`dome impact at: ${position.x, position.y, position.z}, normal: ${normal.x}, ${normal.y}, ${normal.z}`)
    // TODO: Add dome impact fx, orient to normal
}