import { Plane } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import {
  DoubleSide,
  Euler,
  Group,
  Vector3,
  TextureLoader,
  AdditiveBlending,
} from "three";

type ModelProps = {
  position: Vector3;
  normal: Vector3;
  onComplete: (uid: string) => void;
  uid: string;
};

const numTiles = 10; // number of tiles in the sprite file
const tileDisplayDuration = 75; // MS. FPS = 1000 / tileDisplayDuration
export const DomeHit = ({ position, normal, uid, onComplete }: ModelProps) => {
  // console.log("🚀 ~ normal", normal);
  const hitRef = useRef<Group>(null);
  const texture = useLoader(TextureLoader, "dome.png");
  useEffect(() => {
    texture.repeat.set(1 / numTiles, 1);
  }, [texture]);

  useFrame(({ clock }) => {
    const currentTile =
      Math.round((clock.getElapsedTime() * 1000) / tileDisplayDuration) %
      numTiles;
    texture.offset.x = currentTile / numTiles;
  });

  useEffect(() => {
    setTimeout(() => {
      // TODO: Should call onComplete when animate ends.
      onComplete(uid);
    }, 500);
  }, [onComplete, uid]);

  useFrame(() => {
    // hitRef.current?.rotation.set(normal.x, normal.z, normal.y * 1);
  });

  const rotation = new Euler(0, normal.z * 1.5708 + 1.5708, 0);

  return (
    <>
      <group
        position={position}
        rotation={rotation}
        key={uid}
        ref={hitRef}
        dispose={null}
      >
        <Plane scale={3} dispose={null}>
          <meshStandardMaterial
            map={texture}
            side={DoubleSide}
            blending={AdditiveBlending}
          />
        </Plane>
      </group>
    </>
  );
};

// useGLTF.preload(modelPath);
