import { useHelper } from "@react-three/drei";
import { Vector3 } from "@react-three/fiber";
import { useControls, folder } from "leva";
import { useRef } from "react";
import {
  PointLight,
  PointLightHelper,
  SpotLight,
  SpotLightHelper,
} from "three";

type ModelProps = {};

export const GoalLights = ({}: ModelProps) => {
  const pointLightRightRef = useRef<PointLight>(null!);
  const helperRightGoal = useHelper(pointLightRightRef, PointLightHelper);
  const pointLightLeftRef = useRef<PointLight>(null!);
  const helperLeftGoal = useHelper(pointLightLeftRef, PointLightHelper);

  const [
    {
      goal_helper,
      goal_color,
      goal_distance,
      goal_intensity,
      goalL_position,
      goalR_position,
      goal_decay,
    },
    set,
  ] = useControls(() => ({
    goalight: folder({
      goal_helper: { value: false },
      goalR_position: [6, 0.5, 0],
      goalL_position: [-6, 0.5, 0],
      goal_intensity: {
        value: 0.85,
        min: 0,
        max: 10,
        step: 0.001,
      },
      goal_color: "#00ff40",

      goal_distance: {
        value: 35,
        min: 0,
        max: 150,
        step: 1,
      },
      goal_decay: {
        value: 2,
        min: 0,
        max: 5,
        step: 0.01,
      },
    }),
  }));

  if (helperRightGoal.current && helperLeftGoal.current) {
    helperRightGoal.current.visible = goal_helper;
    helperLeftGoal.current.visible = goal_helper;
  }
  return (
    <>
      <pointLight
        color={goal_color}
        shadow-normalBias={1}
        position={goalL_position}
        intensity={goal_intensity}
        castShadow
        receiveShadow
        ref={pointLightLeftRef}
        distance={goal_distance}
        decay={goal_decay}
      />
      <pointLight
        color={goal_color}
        shadow-normalBias={1}
        position={goalR_position}
        intensity={goal_intensity}
        castShadow
        receiveShadow
        ref={pointLightRightRef}
        distance={goal_distance}
        decay={goal_decay}
      />
    </>
  );
};
