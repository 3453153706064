import { Vector3 } from "three";
import type { GameState } from "./ReplayModel";
import { ScoreboardActionType, ScoreboardAction } from "./scoreboardReducer";
import { generateGoalFx } from "./replayFX";
export enum AnimationTrigger {
  // Idle = "Idle",
  // Shoot = "Shoot",
  // Tackle = "Tackle",
  // Celebrate = "Celebrate",
  // Dizzy = "Dizzy",
  // PassLeft = "PassLeft",
  // Sad = "Sad",
  // PowerUp = "PowerUp",

  Blocking = "CharacterRig|Blocking",
  Celebrate = "CharacterRig|Celebrate",
  Charging = "CharacterRig|Charging",
  Dizzy = "CharacterRig|Dizzy",
  Idle = "CharacterRig|Idle",
  IdleLeft = "CharacterRig|IdleLeft",
  IdleRight = "CharacterRig|IdleRight",
  Pass = "CharacterRig|Pass",
  PowerUp = "CharacterRig|PowerUp",
  Run = "CharacterRig|Run",
  RunLeft = "CharacterRig|RunLeft",
  RunRight = "CharacterRig|RunRight",
  Sad = "CharacterRig|Sad",
  Shoot = "CharacterRig|Shoot",
  Stealing = "CharacterRig|Stealing",
  Tackle = "CharacterRig|Tackle",
  Walk = "CharacterRig|Walk",
  WalkLeft = "CharacterRig|WalkLeft",
  WalkRight = "CharacterRig|WalkRight",
}

export type Vector3XYZ = {
  x: number;
  y: number;
  z: number;
};

export type RecordedEvent = {
  normal?: Vector3XYZ;
  teamA?: boolean | number;
  teamB?: number;
  isSuperShot?: boolean;
  isRunning?: boolean;
  animTrigger: keyof typeof AnimationTrigger;
  isUp?: boolean;
  rotation?: number;
  position?: Vector3XYZ;
  playerIndex?: number;
  pos?: Vector3XYZ;
};

class Event {
  handler(
    gameState: GameState,
    scoreboardDispatch: React.Dispatch<ScoreboardAction>
  ) {
    console.log("default handler");
  }
}

class RecordedEvent_Form extends Event {
  playerIndex: number;
  constructor(playerIndex: number) {
    super();
    this.playerIndex = playerIndex;
  }
}

class RecordedEvent_Ball extends Event {}

class RecordedEvent_FormTransform extends RecordedEvent_Form {
  position: Vector3XYZ;
  rotation: number;
  constructor(playerIndex: number, position: Vector3XYZ, rotation: number) {
    super(playerIndex);
    this.position = position;
    this.rotation = rotation;
  }
  handler(gameState: GameState) {
    const playerFromState = gameState.players[this.playerIndex];
    playerFromState.position = new Vector3(
      this.position.x,
      this.position.y,
      -this.position.z
    );
    playerFromState.rotation =
      // this is from the unity function ByteToDegrees, @James Lambourn
      this.rotation * Math.PI * (180 / Math.PI) * 0.01;
  }
}

class EventAnimTrigger extends RecordedEvent_Form {
  animTrigger: keyof typeof AnimationTrigger;
  constructor(playerIndex: number, animTrigger: keyof typeof AnimationTrigger) {
    super(playerIndex);
    this.animTrigger = animTrigger;
  }
  handler(
    gameState: GameState,
    scoreboardDispatch: React.Dispatch<ScoreboardAction>
  ) {
    const playerFromState = gameState.players[this.playerIndex];
    playerFromState.animationTrigger = AnimationTrigger[this.animTrigger];

    if (this.animTrigger === "Tackle") {
      if (this.playerIndex < 4) {
        scoreboardDispatch({ type: ScoreboardActionType.tackleTeamA });
      } else {
        scoreboardDispatch({ type: ScoreboardActionType.tackleTeamB });
      }
    }
    // Debug purpose only
    gameState.pastEvents.players[this.playerIndex].frame =
      gameState.currentFrame;
    gameState.pastEvents.players[this.playerIndex].eventType =
      playerFromState.animationTrigger;
  }
}

class EventAnimIsRunning extends RecordedEvent_Form {
  isRunning: boolean;
  constructor(playerIndex: number, isRunning: boolean) {
    super(playerIndex);
    this.isRunning = isRunning;
  }
  handler(gameState: GameState) {
    const playerFromState = gameState.players[this.playerIndex];
    playerFromState.animationTrigger = AnimationTrigger.Run;
  }
}

class EventSuperSize extends RecordedEvent_Form {
  isUp: boolean;
  constructor(playerIndex: number, isUp: boolean) {
    super(playerIndex);
    this.isUp = isUp;
  }
}

class EventTeleportVFX extends RecordedEvent_Form {
  pos: Vector3XYZ;
  constructor(playerIndex: number, pos: Vector3XYZ) {
    super(playerIndex);
    this.pos = pos;
  }
}

class RecordedEvent_BallTransform extends RecordedEvent_Ball {
  pos: Vector3XYZ;
  constructor(pos: Vector3XYZ) {
    super();
    this.pos = pos;
  }
  handler(gameState: GameState) {
    const ballFromState = gameState.ball;
    ballFromState.position.set(this.pos.x, this.pos.y, -this.pos.z);
  }
}

class RecordedEvent_BallSuperShot extends RecordedEvent_Ball {
  isSuperShot: boolean;
  constructor(isSuperShot: boolean) {
    super();
    this.isSuperShot = isSuperShot;
  }
  handler(gameState: GameState) {
    gameState.ball.isSuperShot = this.isSuperShot;
  }
}

class RecordedEvent_BallPossession extends RecordedEvent_Ball {
  // -1 means no one
  playerIndex: number;
  constructor(playerIndex: number) {
    super();
    this.playerIndex = playerIndex;
  }
  handler(
    gameState: GameState,
    scoreboardDispatch: React.Dispatch<ScoreboardAction>
  ) {
    const ballFromState = gameState.ball;
    ballFromState.ownerIndex =
      this.playerIndex === -1 ? undefined : this.playerIndex;
    if (this.playerIndex < 4) {
      scoreboardDispatch({ type: ScoreboardActionType.passTeamA });
    } else {
      scoreboardDispatch({ type: ScoreboardActionType.passTeamB });
    }
  }
}

class RecordedEvent_DomeImpact extends Event {
  position: Vector3XYZ;
  normal: Vector3XYZ;
  constructor(position: Vector3XYZ, normal: Vector3XYZ) {
    super();
    this.position = position;
    this.normal = normal;
  }
}

class RecordedEvent_GameState extends Event {}

class RecordedEvent_Kickoff extends RecordedEvent_GameState {
  teamA: number;
  teamB: number;
  constructor(teamA: number, teamB: number) {
    super();
    this.teamA = teamA;
    this.teamB = teamB;
  }
}

class RecordedEvent_Goal extends RecordedEvent_GameState {
  teamA: boolean;
  constructor(teamA: boolean) {
    super();
    this.teamA = teamA;
  }
  handler(
    gameState: GameState,
    scoreboardDispatch: React.Dispatch<ScoreboardAction>
  ) {
    if (this.teamA) {
      scoreboardDispatch({ type: ScoreboardActionType.scoreA });
      gameState.score = {
        teamA: gameState.score.teamA + 1,
        teamB: gameState.score.teamB,
        teamScored: "teamA",
      };
    } else {
      scoreboardDispatch({ type: ScoreboardActionType.scoreB });
      gameState.score = {
        teamA: gameState.score.teamA,
        teamB: gameState.score.teamB + 1,
        teamScored: "teamB",
      };
    }
    generateGoalFx(this.teamA ? "teamA" : "teamB");
  }
}

export const parseEvent = (event: RecordedEvent): Event => {
  if (event.playerIndex !== undefined && Object.keys(event).length > 1) {
    // Form Event
    if (event.position !== undefined && event.rotation !== undefined) {
      return new RecordedEvent_FormTransform(
        event.playerIndex,
        event.position,
        event.rotation
      );
    }
    if (event.animTrigger !== undefined) {
      return new EventAnimTrigger(event.playerIndex, event.animTrigger);
    }
    if (event.isUp !== undefined) {
      return new EventSuperSize(event.playerIndex, event.isUp);
    }
    if (event.pos !== undefined) {
      return new EventTeleportVFX(event.playerIndex, event.pos);
    }
    if (event.isRunning !== undefined) {
      return new EventAnimIsRunning(event.playerIndex, event.isRunning);
    }
  }

  if (Object.keys(event).length === 1) {
    // Ball Event
    if (event.pos !== undefined) {
      return new RecordedEvent_BallTransform(event.pos);
    }
    if (event.isSuperShot !== undefined) {
      return new RecordedEvent_BallSuperShot(event.isSuperShot);
    }
    if (event.playerIndex !== undefined) {
      return new RecordedEvent_BallPossession(event.playerIndex);
    }
  }

  if (typeof event.teamA === "boolean") {
    return new RecordedEvent_Goal(event.teamA);
  }

  if (typeof event.teamA === "number" && typeof event.teamB === "number") {
    return new RecordedEvent_Kickoff(event.teamA, event.teamB);
  }

  return new Event();
};
