import { useReducer } from "react";
const initialScoreboardState = {
  totalPassesTeamA: 0,
  totalPassesTeamB: 0,
  totalTacklesTeamA: 0,
  totalTacklesTeamB: 0,
  scoreA: 0,
  scoreB: 0,
};

type ScoreboardState = typeof initialScoreboardState;

export enum ScoreboardActionType {
  passTeamA,
  passTeamB,
  tackleTeamA,
  tackleTeamB,
  scoreA,
  scoreB,
}

export type ScoreboardAction = {
  type: ScoreboardActionType;
};

function scoreboardReducer(state: ScoreboardState, action: ScoreboardAction) {
  switch (action.type) {
    case ScoreboardActionType.passTeamA:
      return {
        ...state,
        totalPassesTeamA: state.totalPassesTeamA + 1,
      };
    case ScoreboardActionType.passTeamB:
      return {
        ...state,
        totalPassesTeamB: state.totalPassesTeamB + 1,
      };
    case ScoreboardActionType.tackleTeamA:
      return {
        ...state,
        totalTacklesTeamA: state.totalTacklesTeamA + 1,
      };
    case ScoreboardActionType.tackleTeamB:
      return {
        ...state,
        totalTacklesTeamB: state.totalTacklesTeamB + 1,
      };
    case ScoreboardActionType.scoreA:
      return {
        ...state,
        scoreA: state.scoreA + 1,
      };
    case ScoreboardActionType.scoreB:
      return {
        ...state,
        scoreB: state.scoreB + 1,
      };
    default:
      throw new Error();
  }
}

export const useScoreboardReducer = () => {
  return useReducer(scoreboardReducer, initialScoreboardState);
};
