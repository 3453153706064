import { useRef, useState, useEffect } from "react";

const parseJSON = () =>
  new TransformStream({
    transform(chunk, controller) {
      controller.enqueue(JSON.parse(chunk));
    },
  });

const splitStream = (splitOn: string) => {
  let buffer = "";
  return new TransformStream({
    transform(chunk, controller) {
      buffer += chunk;
      const parts = buffer.split(splitOn);
      parts.slice(0, -1).forEach((part) => controller.enqueue(part));
      buffer = parts[parts.length - 1];
    },
    flush(controller) {
      if (buffer) controller.enqueue(buffer);
    },
  });
};

// fetch Newline Delimited JSON using stream api
// ref: https://gist.github.com/nestarz/1fa7ae93fb83f1eafb1b88c3a84f2e02
const fetchJSONLD = (url: string) =>
  fetch(url).then((response) => ({
    response,
    reader: response?.body
      ?.pipeThrough(new TextDecoderStream())
      // Needed to stream by line and then JSON parse the line
      .pipeThrough(splitStream("\n"))
      .pipeThrough(parseJSON())
      .getReader(),
  }));

export const useFetchNDJSON = <T>(url: string) => {
  const resultRef = useRef<T[]>([]);

  useEffect(() => {
    fetchJSONLD(url).then(({ response, reader }) => {
      const onReadChunk = async (chunk: ReadableStreamReadResult<T>) => {
        if (chunk.done) {
          return;
        }

        resultRef.current.push(chunk.value);

        reader?.read().then(onReadChunk);
      };
      reader?.read().then(onReadChunk);
    });
  }, []);
  return resultRef;
};
